/* globals Calendly */

( () => {
	// Calendly
	window.addEventListener( 'click', ( e ) => {
		if ( e.target.classList.contains( 'calendly-init' ) ) {
			e.preventDefault();
			Calendly.initPopupWidget( {
				url: 'https://calendly.com/john-ten4/consultation'
			} );
			return false;
		}
	} );
} )();

( () => {
	// Header animation interacting with video
	const demo_video = document.querySelector( '.demo-video__media' );
	const header = document.querySelector( '.header' );
	if ( !demo_video || !header ) {
		return;
	}

	const animation_steps = {
		1: 1200,
		2: 2700,
		3: 4200,
		4: 12600,
		5: 14900,
		6: 19200
	};

	demo_video.addEventListener( 'playing', () => {
		header.classList.add( 'header--animate' );
		headerAnimation();
		pillsAnimation();
	} );

	demo_video.addEventListener( 'ended', () => {
		demo_video.play();
		headerAnimation();
		pillsAnimation();
	} );

	function headerAnimation() {
		const header_imgs = document.querySelectorAll( '.header__img' );

		header_imgs.forEach( ( header_img ) => {
			header_img.classList.remove( 'header__img-step--slide-down' );
		} );

		Object.entries( animation_steps ).forEach( ( [ key, value ] ) => {
			setTimeout( () => {
				document.querySelector( '.header__img-step-' + key ).classList.add( 'header__img-step--slide-down' );
			}, value );
		} );
	}

	function pillsAnimation() {
		const header_nav_pill = document.querySelector( '.header__nav .pill' );
		const header_content_pill = document.querySelector( '.header__content .pill' );

		Object.entries( animation_steps ).forEach( ( [ key, value ] ) => {
			setTimeout( () => {
				if ( key === '1' ) {
					header_nav_pill.classList.remove( 'pill--step-' + Object.keys( animation_steps ).length );
				} else {
					header_nav_pill.classList.remove( 'pill--step-' + [ key - 1] );
				}
				header_nav_pill.classList.add( 'pill--step-' + key );
			}, [ value + 50 ] );

			setTimeout( () => {
				if ( key === '1' ) {
					header_content_pill.classList.remove( 'pill--step-' + Object.keys( animation_steps ).length );
				} else {
					header_content_pill.classList.remove( 'pill--step-' + [ key - 1] );
				}
				header_content_pill.classList.add( 'pill--step-' + key );
			}, [ value + 150 ] );
		} );
	}
} )();
